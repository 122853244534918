<template>
  <div class="echarts" :id="id"></div>
</template>

<script>
import { onMounted, watch } from "vue";
import * as echarts from "echarts";
export default {
  name: "TwokeVueEcharts",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    uid: {
      type: Number,
    },
  },
  setup(ctx) {
    const id = `vue-echarts-${ctx.uid}`;
    let chart = null;
    const initEcharts = () => {
      if (!chart) {
        const dom = document.getElementById(id);
        chart = echarts.init(dom);
      } else {
        return;
      }
      if (!ctx.options) return;
      chart.setOption(ctx.options);
    };
    watch(ctx.options, (newVal) => {
      chart.setOption(newVal);
    },{
      deep:true
    });
    onMounted(() => {
      initEcharts();
    });

    return {
      id,
    };
  },
};
</script>
<style scoped>
.echarts {
  width: 100%;
  height: 100%;
}
</style>